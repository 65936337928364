import React, {useEffect, useState} from 'react'
import {apiGetOrders} from "../../apis";
import {Table} from "antd";
import {BiEdit} from "react-icons/bi";
import {RiDeleteBin6Line} from "react-icons/ri";


const ManageOrder = () => {
    const [orders, setOrders] = useState(null)

    const fetchOders = async (params) => {

        const response = await apiGetOrders({
            ...params,
            limit: process.env.REACT_APP_LIMIT,
        })

        if (response.success) {
            setOrders(response.response)
        }
    }
    useEffect(() => {
        fetchOders()
    }, []);


    const columns = [
        {
            title: 'STT',
            dataIndex: '_id',
            key: '_id',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Total',
            dataIndex: 'total',
            key: 'total',
        },
        {
            title: "Action",
            dataIndex: "",
            key: "x",
            render: () => {
                return (
                    <div className="flex gap-2">
                        <button className="text-blue-500"><BiEdit/></button>
                        <button className="text-red-500"><RiDeleteBin6Line/></button>

                    </div>
                )

            },
        }
    ];

    console.log("orders", orders)
    return (
        <div className="w-full flex flex-col gap-4 relative">
            <h1 className={"text-blue-500 text-4xl mt-5 ml-10"}>Manage Order</h1>
            <div className={"p-10"}>
            <Table columns={columns}  dataSource={orders} rowKey={orders}/>

            </div>
        </div>
    )
}

export default ManageOrder